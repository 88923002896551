<template>

      <div class="p-5">
        <LoadQuery v-slot="{data}" :query="'User/get-dynamicdashdata/' + appid" :variables="null">
          <div v-if="data">

            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-10">
              <div v-for="item in data.data" :key="item.description" :style="{ backgroundColor: item.color, color: 'white' }" class="h-40 rounded-xl shadow-md flex flex-col justify-center items-center">
                  <span class="text-xl uppercase text-center">{{ item.description }}</span>
                  <span class="text-5xl">{{ item.amount }}</span>
              </div>
          </div>

          </div>
        </LoadQuery>
      </div>
</template>

<script>

//import TableTemplate from '@/components/TableTemplate.vue';
import LoadQuery from '@/components/LoadQuery.vue';
import antoniomixin from '@/mixin/antoniomixin';

export default {
  mixins:[antoniomixin],
  components: {
    //TableTemplate,
    LoadQuery
  },
  data(){
    return{
      haveuser: false,
      appid: 0
    }
  },
  mounted(){
    if(this.AppId > 0){
      this.appid = this.AppId
    }
  }
}
</script>

