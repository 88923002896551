<template>
    <div>
        <slot :data="data"></slot>
    </div>
</template>

<script>
export default {
    props:{
        query: String,
        variables: String,
        refresh: Boolean
    },
    data() {
        return{
            data: null
        }
    },
    mounted(){
        this.loadquery()
    },
    methods:{
        async loadquery(){
            await this.axios.get(this.query,{ params: JSON.parse(this.variables) } ).then((response) => {
                this.data = response.data
                this.$emit('onChangeData',this.data)
            })
        }
    },
    watch:{
        query(){
            this.loadquery()
        },
        variables(){
            this.loadquery()
        },
        refresh(){
            this.loadquery()
        }
    }
}
</script>