<template>
    <BlankLayout>
        <div class="flex">
            <div class="h-screen bg-black hidden md:block md:w-2/3"></div>
            <div class="h-screen flex items-center justify-between w-full md:w-1/3">
                <div class="w-full md:mx-20">
                    <ValidationView v-slot="{validated, isvalid}" :inputs="JSON.stringify(credential)" :vinputs="JSON.stringify(vcredential)">
                        <InputTemplate v-slot="{textbox, label, button, validation}">
                            <div class="mb-5 mx-5">
                                <label :class="label">Username</label>
                                <input v-model="credential.Username" :class="textbox" @keydown.enter="Login" />
                                <span v-if="validated != null && validated.Username.message1 == 'empty'" :class="validation">&nbsp; required field</span>
                            </div>

                            <div class="mb-5 mx-5">
                                <label :class="label">Password</label>
                                <input v-model="credential.Password" type="password" :class="textbox" @keydown.enter="Login" />
                                <span v-if="validated != null && validated.Password.message1 == 'empty'" :class="validation">&nbsp; required field</span>
                            </div>

                            <div class="mt-5 mb-5 mx-5">
                                <button @click="Login()" :disabled="isvalid == false" class="bg-blue-500 w-full" :class="button">Login</button>
                            </div>

                            <div v-if="loginfail" id="alert-border-2" class="flex items-center mt-5 mb-5 mx-5 p-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-gray-800 dark:border-red-800" role="alert">
                                <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                                </svg>
                                <div class="ms-3 text-sm font-medium">
                                Invalid credential.
                                </div>
                            </div>

                        </InputTemplate>
                    </ValidationView>
                </div>
            </div>
        </div>
    </BlankLayout>
</template>

<script>
import BlankLayout from '@/layout/BlankLayout.vue'
import InputTemplate from '@/components/InputTemplate.vue'
import ValidationView from '@/components/ValidationView.vue'
import antoniomixin from '@/mixin/antoniomixin'

export default {
    mixins: [antoniomixin],
    components: {
        InputTemplate,
        BlankLayout,
        ValidationView
    },
    data() {
        return {
            credential: {
                Username: null,
                Password: null
            },
            vcredential: {
                Username: { required: true, message1: null, error: false },
                Password: { required: true, message1: null, error: false }
            },
            loginfail: false
        }
    },
    methods: {
        async Login() {
            await this.axios.get("User/login", { params: this.credential }).then((response) => {
                if (response.data.error == false) {
                    localStorage.token = response.data.token
                    localStorage.name = response.data.firstName + " " + response.data.lastName
                    localStorage.departmentId = response.data.departmentid
                    localStorage.user = response.data.user
                    localStorage.appid = 0
                    localStorage.department = response.data.department
                    window.location.reload()
                }
                else{
                    this.loginfail = true
                }
            }).catch(() => {
                this.loginfail = true
            }) 
        }
    }
}
</script>
