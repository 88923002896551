<template>
    <div>
        <slot :validated="validateddata" :isvalid="isvalid"></slot>
    </div>
</template>

<script>
export default {
    props:{
        inputs: String,
        vinputs: String
    },
    data(){
        return{
            validateddata: null
        }
    },
    computed:{
        isvalid(){
            for(let value in this.validateddata){
                if(this.validateddata[value].error == true){
                    return false
                }
            }
            return true
        }
    },
    mounted(){
       this.validateinputs() 
    },
    methods:{
        validateinputs() {
            let input = JSON.parse(this.inputs);
            let vinput = JSON.parse(this.vinputs);

            for (let value in vinput) {
                if (vinput[value].required == true) {
                    if (input[value] === undefined || input[value] === null || input[value] === '') {
                        vinput[value].error = true;
                        vinput[value].message1 = "empty";
                    } else {
                        vinput[value].error = false;
                        vinput[value].message1 = "";
                    }
                }
            }

            this.validateddata = vinput;

            this.$emit('onValidated', this.isvalid);
        }

    },
    watch:{
        inputs(){
            this.validateinputs()
        },
        vinputs(){
            this.validateinputs()
        }
    }
}
</script>