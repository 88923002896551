<template>
    <div :class="{ 'transform -translate-y-full' : !showModal }" class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-75 transition ease-in-out duration-200">
        <div class="relative mx-auto my-auto w-auto max-w-xl px-5">
            <div class="flex gap-2 shadow-lg bg-white border-2 border-black border-solid rounded-md w-full p-3 dark:bg-white dark:text-black">
               <div class=" w-80">
                    <h3 class="flex gap-2 items-center text-xl font-semibold text-gray-900 dark:text-white">
                        <div class="bg-blue-500 w-10 h-10 flex items-center justify-center rounded-full text-white">
                            <SvgIcons iconname="building-storefront"></SvgIcons>
                        </div>
                        
                        <span class="dark:text-black">Choose Application</span> 
                    </h3>
                    <hr class="my-3">
                    <InputTemplate v-slot="{select, label}">
                        <label :class="label">Applications</label>
                        <LoadQuery :query="'AppsName/get-userapppbyuser'" :variables="null" v-slot="{data}">
                            <select @change="choosecompany()" v-model="app" v-if="data!=null" :class="select">
                                <option v-for="item in data.userapp" :key="item.lineId" :value="{ appid: item.appsId, appname: item.appsName, type: item.type }">{{ item.appsName }}</option>
                            </select>
                        </LoadQuery>
                    </InputTemplate>
               </div>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcons from '@/components/SvgIcon.vue';
import InputTemplate from './InputTemplate.vue';
import LoadQuery from './LoadQuery.vue';
export default {
    components:{
        SvgIcons,
        InputTemplate,
        LoadQuery
    },
    props: {
        showModal: Boolean
    },
    data(){
        return{
            app: {
                appid: null,
                appname: null,
                type: null
            }
        }
    },
    methods:{
        choosecompany(){
            localStorage.appid = this.app.appid
            localStorage.appname = this.app.appname
            localStorage.type = this.app.type
            this.$emit("onSelectCompany", this.appid)
        }
    }
}
</script>