export default{
    data(){
        return{
            months: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            longmonths:['January','February','March','April','May','June','July','August','September','October','November','December'],
            filters:{
                q: null,
                displayentries: 10000,
                page: 1,
                appid: Number(localStorage.appid)
            },
            activefilters:{
                appid: Number(localStorage.appid),
                q: null
            },
            activefilters1:{
                appid: Number(localStorage.appid),
                q: null
            },
            IsApprover: false,
            access:{
                DASHBOARD: false,
                PURCHASEREQUEST: false,
                PURCHASEORDER: false,
                RECEIVING: false,
                TRANSFERIN: false,
                TRANSFEROUT: false,
                PHYSICALCOUNT: false,
                PHYSICALCOUNTAPPROVER: false,
                CAPEX: false,
                PURCHASESUMMARYREPORT: false,
                PURCHASEDETAILREPORT: false,
                SUPPLIERLISTREPORT: false,
                PRICECOMPARISONREPORT: false,
                PRICEMOVEMENTREPORT: false,
                SUPPLIERS: false,
                SUPPLIERSPRICE: false,
                DEPARTMENT: false,
                INVENTORYITEM: false,
                GROUPS: false,
                CATEGORY: false,
                LOCATION: false,
                ROLES: false,
                USERS: false,
                PRFIRSTAPPROVAL: false,
                PRFINALAPPROVAL: false,
                BLANKETPO: false,
                AREA: false,
                UOM: false,
                GLACCOUNT: false,
                APPS: false,
                TRANSFEROUTAPPROVAL: false,
                STOCKREQUEST: false,
                STOCKREQUESTAPPROVAL: false,
                CMDSTOCKREQUESTTRANSFEROUTAPPROVAL: false,
                PURCHASEREQUESTREPORT: false,
                PURCHASEORDERSREPORTS: false,
                TRANSFEROUTREPORT:false,
                CMDTRANSFEROUTAPPR: false,
                DASHBOARDSETTING: false
            },
            AppId: Number(localStorage.appid),
            // baseurl: "http://inventory.agrhr.ph/antonioapp/api/",
            // homeurl: "http://inventory.agrhr.ph/#/",
            // loginurl: "http://inventory.agrhr.ph/#/login",
            baseurl: "https://localhost:44316/",
            homeurl: "/#/",
            loginurl: "/#/login",
            AppName: localStorage.appname,
            Department: localStorage.department,
            TaxRate: 12
        }
    },
    filters:{
        currencyformat(data){
            const money = (data/1).toFixed(2).replace(',', '.')
            return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },

    mounted(){
        if(localStorage.getItem("token") != null){
            this.loadaccesslevel()
        }
    },

    methods:{
        formatPoNumber(num) {
            return num.toString().padStart(5, '0');
        },
        //PICTURE UPLOADING
        async onPictureUploading(file, filename){
            const formData = new FormData()
            formData.append('file', file, file.name)
            formData.append('filename', filename)
            await this.axios.post("User/pictureuploader", formData).then((response) => {
                if(response.data.error == false){
                    this.filename = response.data.filename;
                }
            })
        },
        //FILE UPLOADING
        async onFileUploading(file, filename){
            const formData = new FormData()
            formData.append('file', file, file.name)
            formData.append('filename', filename)
            await this.axios.post("User/upload-file", formData).then((response) => {
                if(response.data.error == false){
                    this.filename = response.data.filename;
                }
            })
        },
        getFileExtension(fileName) {
            // Get the file extension from the file name
            const lastDotIndex = fileName.lastIndexOf('.');
            if (lastDotIndex === -1) {
                // No dot (.) found, no extension
                return "";
            }
            
            // Return the file extension
            return "."+fileName.slice(lastDotIndex + 1);
        },
        formatdate(mydate) {
            let d = new Date(mydate)
            let month = '' + (d.getMonth() + 1)
            let day = '' + d.getDate()
            let year = d.getFullYear()
            if (month.length < 2)
                month = '0' + month
            if (day.length < 2)
                day = '0' + day
            return [year, month, day].join('-')
        },
        formatshortdate(mydate) {
            let d = new Date(mydate)
            let month = '' + (d.getMonth() + 1)
            let day = '' + d.getDate()
            let year = d.getFullYear()
            if (month.length < 2)
                month = '0' + month
            if (day.length < 2)
                day = '0' + day
            return [this.months[month - 1] + ' ' + day, year].join(', ')
        },
        mcurrencyformat(data){
            const money = (data/1).toFixed(2).replace(',', '.')
            return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        
        async loadaccesslevel(){
            await this.axios.get("AccessLevel/get-accesslevelbyusername").then((response) => {
                if(response.data.error == false){
                    response.data.accesslevels.forEach((value) => {
                        if(value.moduleName == "DASHBOARD"){
                            this.access.DASHBOARD = true
                        }

                        if(value.moduleName == "PURCHASE REQUEST"){
                            this.access.PURCHASEREQUEST = true
                        }

                        if(value.moduleName == "PURCHASE ORDER"){
                            this.access.PURCHASEORDER = true
                        }

                        if(value.moduleName == "RECEIVING"){
                            this.access.RECEIVING = true
                        }

                        if(value.moduleName == "TRANSFER IN"){
                            this.access.TRANSFERIN = true
                        }

                        if(value.moduleName == "TRANSFER OUT"){
                            this.access.TRANSFEROUT = true
                        }

                        if(value.moduleName == "PHYSICAL COUNT"){
                            this.access.PHYSICALCOUNT = true
                        }

                        if(value.moduleName == "PHYSICAL COUNT APPROVER"){
                            this.access.PHYSICALCOUNTAPPROVER = true
                        }

                        if(value.moduleName == "CAPEX"){
                            this.access.CAPEX = true
                        }

                        if(value.moduleName == "PURCHASE SUMMARY REPORT"){
                            this.access.PURCHASESUMMARYREPORT = true
                        }

                        if(value.moduleName == "PURCHASE DETAIL REPORT"){
                            this.access.PURCHASEDETAILREPORT = true
                        }

                        if(value.moduleName == "SUPPLIER LIST REPORT"){
                            this.access.SUPPLIERLISTREPORT = true
                        }

                        if(value.moduleName == "PRICE COMPARISON REPORT"){
                            this.access.PRICECOMPARISONREPORT = true
                        }

                        if(value.moduleName == "PRICE MOVEMENT REPORT"){
                            this.access.PRICEMOVEMENTREPORT = true
                        }

                        if(value.moduleName == "SUPPLIERS"){
                            this.access.SUPPLIERS = true
                        }

                        if(value.moduleName == "SUPPLIERS PRICE"){
                            this.access.SUPPLIERSPRICE = true
                        }

                        if(value.moduleName == "DEPARTMENT"){
                            this.access.DEPARTMENT = true
                        }

                        if(value.moduleName == "INVENTORY ITEM"){
                            this.access.INVENTORYITEM = true
                        }

                        if(value.moduleName == "GROUPS"){
                            this.access.GROUPS = true
                        }

                        if(value.moduleName == "CATEGORY"){
                            this.access.CATEGORY = true
                        }

                        if(value.moduleName == "LOCATION"){
                            this.access.LOCATION = true
                        }

                        if(value.moduleName == "ROLES"){
                            this.access.ROLES = true
                        }

                        if(value.moduleName == "USERS"){
                            this.access.USERS = true
                        }

                        if(value.moduleName == "PR FIRST APPROVAL"){
                            this.access.PRFIRSTAPPROVAL = true
                        }

                        if(value.moduleName == "PR FINAL APPROVAL"){
                            this.access.PRFINALAPPROVAL = true
                        }

                        if(value.moduleName == "BLANKET PO"){
                            this.access.BLANKETPO = true
                        }

                        if(value.moduleName == "AREA"){
                            this.access.AREA = true
                        }

                        if(value.moduleName == "UoM"){
                            this.access.UOM = true
                        }

                        if(value.moduleName == "GL ACCOUNT"){
                            this.access.GLACCOUNT = true
                        }

                        if(value.moduleName == "APPS"){
                            this.access.APPS = true
                        }

                        if(value.moduleName == "TRANSFER OUT APPROVAL"){
                            this.access.TRANSFEROUTAPPROVAL = true
                        }

                        if(value.moduleName == "STOCK REQUEST"){
                            this.access.STOCKREQUEST = true
                        }

                        if(value.moduleName == "STOCK REQUEST APPROVAL"){
                            this.access.STOCKREQUESTAPPROVAL = true
                        }

                        if(value.moduleName == "CMD STOCK REQUEST/TRANSFER OUT APPROVAL"){
                            this.access.CMDSTOCKREQUESTTRANSFEROUTAPPROVAL = true
                        }

                        if(value.moduleName == "PURCHASE REQUEST REPORT"){
                            this.access.PURCHASEREQUESTREPORT = true
                        }

                        if(value.moduleName == "PURCHASE ORDERS REPORTS"){
                            this.access.PURCHASEORDERSREPORTS = true
                        }

                        if(value.moduleName == "TRANSFER OUT REPORT"){
                            this.access.TRANSFEROUTREPORT = true
                        }

                        if(value.moduleName == "CMD TRANSFER OUT APPR."){
                            this.access.CMDTRANSFEROUTAPPR = true
                        }

                        if(value.moduleName == "DASH BOARD SETTING"){
                            this.access.DASHBOARDSETTING = true
                        }
                    })
                }
            })
        }
    }
}